<template>
  <div class="commodity-list-bg">
    <div class="commodity-list-con">
      <h2 class="page-title">商品列表</h2>
      <div class="commodity-list">
        <div class="filter-section">
          <div class="section-title">
            <i class="el-icon-search"></i>
            <span>筛选搜索</span>
          </div>
          <div class="filter-section-con">
            <el-form :inline="true" :model="form" size="small">
              <el-form-item label="板块：">
                <el-select v-model="form.plate">
                  <el-option label="全部" value=""></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="品牌：">
                <el-select v-model="form.brands">
                  <el-option label="全部" value=""></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="分类：">
                <el-select v-model="form.classify">
                  <el-option label="全部" value=""></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="风格：">
                <el-select v-model="form.style">
                  <el-option label="全部" value=""></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.keyWords" placeholder="输入品牌名称或关键词搜索"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="query" @click="query">查询</el-button>
              </el-form-item>
              <el-form-item>
                <el-button class="add" @click="add">新增</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="commodity-items">
          <table>
            <thead>
              <tr>
                <th>
                  <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="checkAllChange"></el-checkbox>
                </th>
                <th>商品型号</th>
                <th>商品名称</th>
                <th>品牌</th>
                <th>风格</th>
                <th>分类</th>
                <th>超级会员折扣</th>
                <th>高级会员折扣</th>
                <th>普通会员折扣</th>
                <th>价格</th>
                <th>状态</th>
                <th>发布时间</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr v-for="(item, index) in 1" :key="index">
                <td>
                  <el-checkbox></el-checkbox>
                </td>
                <td>ZD-LPLN-L-DG001C</td>
                <td>优质实木框架 不锈钢饰边磨砂布面料 羽绒填充舒适坐感转角沙发</td>
                <td>光织物·贝兰特</td>
                <td>轻奢</td>
                <td>沙发</td>
                <td>0.34</td>
                <td>0.35</td>
                <td>0.45</td>
                <td>￥37947.00</td>
                <td>
                  <span>上架</span>
                  <el-switch :width="34" active-color="#C6AA92" inactive-color="#999" v-model="val"></el-switch>
                </td>
                <td>
                  <div class="release-time">
                    <span>2021-07-06</span>
                    <span>15:35:28</span>
                  </div>
                </td>
                <td>
                  <div class="operation">
                    <el-button size="small" class="edit">编辑</el-button>
                    <el-button size="small" class="del">删除</el-button>
                    <el-button size="small" class="model-upload">模型上传</el-button>
                    <el-button size="small" class="sun-code">太阳码</el-button>
                  </div>
                </td>
              </tr> -->
              <tr v-for="(item, index) in commodityData.list" :key="index">
                <td>
                  <el-checkbox></el-checkbox>
                </td>
                <td>{{item.goods_marque}}</td>
                <td>{{item.goods_name}}</td>
                <td>{{item.brand_name}}</td>
                <td>{{item.style_name}}</td>
                <td>{{item.goods_type}}</td>
                <td>{{item.operate_discount}}</td>
                <td>{{item.member_discount}}</td>
                <td>{{item.distribution_discount}}</td>
                <td>￥{{item.sale_price}}</td>
                <td>
                  <span>{{item.isLine ? "上架" : "下架"}}</span> 
                  <!-- ? "上架" : "下架" -->
                  <el-switch :width="34" active-color="#C6AA92" inactive-color="#999" v-model="item.isLine"></el-switch>
                </td>
                <td>
                  <div class="release-time">
                    <span>{{item.create_date.split(" ")[0]}}</span>
                    <span>{{item.create_date.split(" ")[1]}}</span>
                  </div>
                </td>
                <td>
                  <div class="operation">
                    <el-button size="small" class="edit">编辑</el-button>
                    <el-button size="small" class="del">删除</el-button>
                    <el-button size="small" class="model-upload">模型上传</el-button>
                    <el-button size="small" class="sun-code">太阳码</el-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paging-wrap">
          <el-button size="mini" class="export-goods" icon="el-icon-download">导出商品</el-button>
          <div class="paging-con">
            <el-pagination
              background
              layout="prev, pager, next, jumper"
              :pager-count="5"
              prev-text="上一页"
              next-text="下一页"
              :total="100">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import dump from "@/util/dump";
  export default {
    data (){
      return {
        form: {
          plate: "",
          brands: "",
          classify: "",
          style: "",
          keyWords: ""
        },
        token: null,
        commodityData: "",
        checkAll: false,
        isIndeterminate: true,
        val: true,
      }
    },
    created() {
      this.init();
    },
    computed:{
      isLine(){
        return (obj) => {
          // let state = false;
          // let state = obj.is_line ? true : false;
          console.log(obj)
          return true;
        }
      }
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getCommodityList();
      },
      getCommodityList(){
        this.$api.commodityList({
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.commodityData = res.data;
            this.commodityData.list.forEach(item => {
              this.$set(item, "isLine", item.is_line ? true : false);
            })
          }
          console.log(this.commodityData.list);
        }).catch(err => console.error(err))
      },
      // 查询
      query(){
        
      },
      // 新增
      add(){
        dump.link({
          type: 1,
          link: "addCommodity"
        });
      },
      // 全选
      checkAllChange(){
        
      },
      // 删除商品
      del(){
        
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/commodityList/commodityList";
</style>
